import * as React from 'react';
import * as styles from './research.module.css';
import ShowcasePage from './showcase/showcase-page';

function Research() {
  return (
    <ShowcasePage>
      <div className={styles.slideshowContainer}>
        <iframe
          className={styles.slideshow}
          src="https://docs.google.com/presentation/d/e/2PACX-1vTAPze_6sXcbK3qPiZnI_k3NgnLZiLAQx7zUp62FSohCxboQz4YRiEFHpKJlCVOQ_IOL5eo-zA6FP3X/embed?start=false&loop=true&delayms=30000"
          frameborder="0"
          width="1440"
          height="839"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        />
      </div>
    </ShowcasePage>
  );
}

export default Research;
